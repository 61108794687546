import Common from '@/assets/js/common.js'
import Constants from '../../config.local.js'
import LieuTransformer from '@/assets/js/dexie/transformers/LieuTransformer.js'
import IntraLocationTransformer from '@/assets/js/dexie/transformers/IntraLocationTransformer.js'
import IntraLocationTypeTransformer from '@/assets/js/dexie/transformers/IntraLocationTypeTransformer.js'
import MouvementTransformer from '@/assets/js/dexie/transformers/MouvementTransformer.js'
import MouvementCleaner from '@/assets/js/cache/cleaners/MouvementCleaner'

let IntraLocationMixin = {
    methods: {
		async getIntraLocations(flat = true) {
			return this.$storage.db.t('intra_location')
				.then(table => {
					if(flat) {
						return table.where({
							intralocation_parent: 0
						})
					}
					return table.toCollection()
				})
				.then(col => {
					if(flat)
						return col.transform(new IntraLocationTransformer('withTypeAndChildren'))
					return col.toArray()
				})
				.then(res => {
					if(flat)
						return this.flattenIntraLocation(res)
					return res
				})
		},

		async getIntraLocation(location_id) {
			location_id = this.$sync.replaceWithReplicated('intra_location', location_id)

			return this.$storage.db.t('intra_location')
				.then(table => {
					return table.where({
						intralocation_id: parseInt(location_id)
					})
				})
				.then(col => {
					return col.toArray()
				})
				.then(res => {
					if(res.length > 0) return res[0]
					return null
				})
		},

		async getResidenceIntraLocations(lieu_id) {
			lieu_id = this.$sync.replaceWithReplicated('lieu', lieu_id)

			return this.$storage.db.t('intra_location')
				.then(table => {
					return table.where({
						intralocation_parent: lieu_id
					})
				})
				.then(col => {
					return col.transform(new IntraLocationTransformer('withTypeAndChildren'))
				})
				.then(res => {
					return this.flattenIntraLocation(res)
				})
		},

		async getHorseIntraLocation(horse_id) {
			horse_id = this.$sync.replaceWithReplicated('horse', horse_id)

			const intra_loc = await this.$storage.db.t('horse_mouvement')
				.then(table => {
					return table.where({
						// mouvement_type: 5,
						mouvement_horse: parseInt(horse_id)
					})
				})
				.then(col => {
					// On exclue les date en 0000-00-00 qui trompent le résultat du sort du transformer
					return col.filter(m => m.mouvement_date != '0000-00-00' && m.mouvement_date !== null)
				})
				.then(col => {
					return col.transform(new MouvementTransformer('withIntraLocation'))
				})
				.then(res => {
					if(res.length > 0) return res[0]
					return null
				})

			if(!intra_loc) return null
			if(intra_loc.mouvement_type != 5) return null
			
			return intra_loc
			// // On regarde si une sortie de la résidence a été effectuée depuis. Si oui, on ne ressort pas de localisation interne
			// const potential_out = await this.$storage.db.t('horse_mouvement')
			// 	.then(table => {
			// 		return table.where({
			// 			mouvement_horse: parseInt(horse_id)
			// 		})
			// 	})
			// 	.then(col => {
			// 		return col.transform(new MouvementTransformer())
			// 	})
			// 	.then(res => {
			// 		return res.filter(mouv => 
			// 			mouv.mouvement_type !== 5    		// que les types sorties
			// 			&& mouv.mouvement_type !== 1
			// 			&& mouv.mouvement_horse == horse_id // pour le même cheval uniquement
			// 			&& mouv.mouvement_date >= intra_loc.mouvement_date 			// d'une date supérieure ou égale
			// 			&& Math.abs(mouv.mouvement_id) >= intra_loc.mouvement_id	// dont l'id (absolu) est supérieur ( si négatif, l'absolu assure aussi que la ligne ait été insérée après)
			// 		)
			// 	})
			// 	.then(res => {
			// 		if(res.length > 0) return res[0]
			// 		return null
			// 	})

			// if(potential_out) return null
			// return intra_loc
		},

		async getIntraLocationsWithresidences(withRoot=true, childFilter=true) {
			const residences = await this.$storage.db.t('lieu')
				.then(table => {
					return table.where({
						lieu_type: 1,
						lieu_archive: 0
					})
				})
				.then(col => {
					return col.transform(new LieuTransformer('withIntraLocation'))
				})

			let used = residences

			if(childFilter) {
				used = residences.filter(res => res.children.length > 0)
			}

			used.sort(function(a, b){
				if (a.intralocation_order < b.intralocation_order) //sort order ascending
				 return -1;
				if (a.intralocation_order > b.intralocation_order)
				 return 1;
				return 0; //default return value (no sorting)
			   });
			let res = this.flattenIntraLocation(used, withRoot)
			return res
		},

		async getIntraLocationsWithAllParents(location_id=0) {
			return this.$storage.db.t('intra_location')
			.then(table => {
				if(location_id != 0) {
					location_id = this.$sync.replaceWithReplicated('intra_location', location_id)

					return table.where({
						intralocation_id: parseInt(location_id)
					})
				}
				return table.toCollection()
			})
			.then(col => {
				return col.transform(new IntraLocationTransformer('withParent'))
			})
		},

		async getIntraLocationTypes() {
			return this.$storage.db.t('intra_location_type')
				.then(table => {
					return table.toCollection()
				})
				.then(col => {
					return col.transform(new IntraLocationTypeTransformer())
				})
		},

		async addIntraLocation(params) {
			params.intralocation_id = Common.getNegativeId()
			params.intralocation_parent_type = params.intralocation_is_root ? 'lieu' : 'intra_location'

			await this.$storage.db.t('intra_location')
				.then(table => {
					return table.add({
						intralocation_id	  : params.intralocation_id,
						intralocation_valide  : 1,
						intralocation_label	  : params.intralocation_label,
						intralocation_horses  : parseInt(params.intralocation_horses),
						intralocation_type    : parseInt(params.intralocation_type),
						intralocation_size    : parseFloat(params.intralocation_size),
						intralocation_parent  : params.intralocation_parent,
						intralocation_parent_type : params.intralocation_parent_type,
						intralocation_shape 	: params.intralocation_shape ? params.intralocation_shape : ''
					})
				})

			// await this.updateParentsProperties(params)
			return params
		},

		async updateIntraLocation(locations) {
			await this.$storage.db.transaction(
                'rw',
                'intra_location',
                () => {
                    locations.forEach((location) => {
                        this.$storage.db.t('intra_location')
                        .then(table => {
                            return table.update(
								parseInt(location.intralocation_id),
								{
									intralocation_id	  : location.intralocation_id,
									intralocation_label	  : location.intralocation_label,
									intralocation_horses  : parseInt(location.intralocation_horses),
									intralocation_type    : parseInt(location.intralocation_type),
									intralocation_size    : parseFloat(location.intralocation_size),
									intralocation_parent  : location.intralocation_parent,
									intralocation_parent_type : location.intralocation_is_root ? 'lieu' : 'intra_location',
									intralocation_shape 	: location.intralocation_shape
								}
							)
                        })
					})
					
					return locations
                }
            )

            let loc_ids = locations.map(loc => loc.intralocation_id)

            return this.$storage.db.t('horse_mouvement')
			.then(table => {
				return table.where({
					mouvement_type: 5
                })
			})
			.then(col => {
				return col.filter(m => loc_ids.includes(m.mouvement_intra_location)).toArray()
			})
			.then(mouvements => {
				return mouvements.map(m => m.mouvement_horse)
			})
			.then(horse_ids => {
				return Promise.all([
					MouvementCleaner.inst().onMutationHorseIntraloc(horse_ids)
				])
			})
		},

		/* Met à jour les répartitions de surface et nb de chevaux sur les parents */
		async updateParentsProperties(params) {
			// Anciennes et nouvelles infos
			const old_location_data = params.old_intralocation
			const actual_location_data = params

			// On récupére les parents de la localisation actuelle, et on en fait un array flat pour avoir toutes les localisation (l'enfant et les parents) au même niveau
			const location = await this.getIntraLocationsWithAllParents(params.intralocation_id)
			let flatten_locations = Common.flattenBy(location, 'parent')

			// On exclue la localisation actuelle à recalculer
			const idx = flatten_locations.find(fl => fl.intralocation_id == params.intralocation_id)
			flatten_locations.splice(idx, 1)

			flatten_locations.forEach(location => {
				location.intralocation_horses += (actual_location_data.intralocation_horses - old_location_data.intralocation_horses)
				location.intralocation_size   += (actual_location_data.intralocation_size   - old_location_data.intralocation_size)
			})

			return this.updateIntraLocation(flatten_locations)
		},

		flattenIntraLocation(locations, withRoot=true, indent=0) {
			let flatten = []
			
			locations.forEach(location => {
				if (location.children){
					location.children.sort(function(a, b){
						if (a.intralocation_order < b.intralocation_order) //sort order ascending d'abord
							return -1;
						if (a.intralocation_order > b.intralocation_order)
							return 1;
						// Puis sort alphabétique (sans les chiffres)
						if (a.intralocation_label.replace(/[0-9]/g, '') < b.intralocation_label.replace(/[0-9]/g, '')) //sort label ascending
							return -1;
						if (a.intralocation_label.replace(/[0-9]/g, '') > b.intralocation_label.replace(/[0-9]/g, ''))
							return 1;
						// Puis on compare avec les chiffres si les strings sont pareils
						if (parseInt(a.intralocation_label.replace(/\D/g,'')) < parseInt(b.intralocation_label.replace(/\D/g,'')))
							return -1;
						if (parseInt(a.intralocation_label.replace(/\D/g,'')) > parseInt(b.intralocation_label.replace(/\D/g,'')))
							return 1;
						return 0; //default return value (no sorting)
					});
				}
				if(!withRoot && location.intralocation_is_root === true) {
					// continue
				}
				else {
					location.indent = indent
					flatten.push(location)
				}

				flatten = flatten.concat(
					this.flattenIntraLocation(location.children, withRoot, indent+1)
				)
			})

			return flatten
		},

		async deleteIntraLocation(location) {
			// j'appelle la méthode en récursif sur les enfants
			await Common.asyncForEach(location.children, async loc => {
				await this.deleteIntraLocation(loc)
			})

			// je supprime les mouvements liés
			await this.$storage.db.t('horse_mouvement')
			.then(table => {
				return table.where({
					mouvement_intra_location: parseInt(location.intralocation_id)
                })
				.invalid()
			})
			// je supprime la location interne
			return this.$storage.db.t('intra_location')
            .then(table => {
            	return table.where({
					intralocation_id: parseInt(location.intralocation_id)
                })
				.invalid()
            })
		},

		uploadImageIntraLoc: async function(intralocation_id, document_file) {
			const url = this.constructRoute(Constants.INTRALOCATION_IMAGE_URL, {intralocation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY

			return this.$request.request_post_file_api("IntraLocationMixin::uploadImageIntraLoc", url, [{ 
					name: 'document',
					content: document_file
				}])
				.catch(e => {
					console.error("IntraLocationMixin::uploadImageIntraLoc => ERROR", e)
					return null
				})
				.then(res => {
					return res.retour
				})
		},

		getIntraLocationsWithActeByIntraLocation: async function(intralocation_id, date, contacts_ids) {
			let url = this.constructRoute(Constants.INTRALOCATION_LOCALISATION_ACTE_URL, {intralocation_id}) + "?licence_key="+Constants.USER_LICENCE_KEY+'&date='+date
			if(contacts_ids.length > 0) {
				url += '&contacts_ids='+contacts_ids.join(',')
			}
			const result = await this.$request.request_get_api("IntraLocationMixin::getIntraLocationsWithActeByIntraLocation", url)
			if(result) return result.retour
			return null
		},

		async saveIntraLocationOrder(localisations) {
			await Common.asyncForEach(localisations, async (loc) => {
				await this.$storage.db.t('intra_location')
                .then(table => {
                    return table.update(parseInt(loc.intralocation_id), {
                        intralocation_order: loc.intralocation_order,
                    })
                })
			})
		},

		async saveLieuOrder(lieux) {
			await Common.asyncForEach(lieux, async (lieu) => {
				await this.$storage.db.t('lieu')
                .then(table => {
                    return table.update(parseInt(lieu.intralocation_id), {
                        lieu_order: lieu.intralocation_order,
                    })
                })
			})
		},
	}
}

export default IntraLocationMixin